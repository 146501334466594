<template>
  <a-form :model="formState" name="basic" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }" autocomplete="off"
    @finish="onFinish" @finishFailed="onFinishFailed">
    <a-form-item label="" name="phone"
      :rules="[{ required: true, message: '请输入手机号!' }, { len: 11, message: '请输入正确的格式' }]">
      <a-input size="large" prefix="+86" :maxlength="11" type="tel" placeholder="请输入手机号"
        v-model:value="formState.phone" />
    </a-form-item>

    <a-form-item label="" name="password" :rules="[{ required: true, message: '请输入密码!' }]">
      <a-input-password size="large" placeholder="请设置登录密码" v-model:value="formState.password" />
    </a-form-item>
    <a-form-item label="" name="repassword" :rules="[{ required: true, message: '请输入验证密码!' }]">
      <a-input-password size="large" placeholder="再次输入登录密码" v-model:value="formState.repassword" />
    </a-form-item>

    <a-form-item label="" name="code" :rules="[{ required: true, message: '请输入短信验证码!' }]">
      <a-input size="large" placeholder="请输入短信验证码" v-model:value="formState.code">
        <template #suffix>
          <a-button type="link" @click="startQue" :disabled="countDown.idx > 0">{{ countDownText }}</a-button>
        </template>
      </a-input>
    </a-form-item>

    <a-form-item name="remember" :wrapper-col="{ offset: 0, span: 24 }">
      <a-checkbox v-model:checked="formState.remember">
        <div class="tip">
          我已阅读并同意
        </div>
      </a-checkbox>
        
          <span class="span font-primary" @click="showfwxy()">《企政牛平台服务协议》</span>、
          <span class="span font-primary" @click="showyszc()">《企政牛隐私权政策》</span>
        
      
    </a-form-item>

    <a-form-item :wrapper-col="{ offset: 0, span: 24 }">
      <a-button :loading="loading" type="primary" size="large" block html-type="submit">立即注册</a-button>
    </a-form-item>
  </a-form>

  <a-modal v-model:visible="visible_fwxy" width="1000px" :footer="null" title="企政牛平台服务协议" @ok="handleOk">
      <p class="text-area">{{ agreement.fwxy }}</p>
  </a-modal>
  <a-modal v-model:visible="visible_yszc" width="1000px" :footer="null" title="企政牛隐私权政策" @ok="handleOk">
    <p class="text-area">{{ agreement.yszc }}</p>
  </a-modal>
</template>
<script>
import { message } from 'ant-design-vue';
import { mapActions } from 'vuex';
import service from '../../../service/service';

export default {
  props: ['isAdmin'],
  data() {
    return {
      formState: {
        phone: '',
        password: '',
        repassword: '',
        code: '',
        remember: false,
      },
      visible_fwxy:false,
      visible_yszc:false,
      agreement:[],
      countDown: {
        idx: 0,
      },
      loading: false,
    }
  },
  computed: {
    countDownText() {
      return this.countDown.idx <= 0 ? '获取验证码' : this.countDown.idx;
    }
  },
  mounted(){
    this.get_agreement();
  },
  methods: {
    ...mapActions(['getUserinfo']),
    async get_agreement(){
      let res = await service.get_agreement()
      if(res.code == 1){
        this.agreement = res.data
      }
      console.log(this.agreement)
    },
    showfwxy(){
      this.visible_fwxy = true
    },
    showyszc(){
      this.visible_yszc = true
    },
    handleOk(){
      this.visible_fwxy = false
      this.visible_ysxy = false
    },
    async startQue() {
      //console.log(this.formState.phone)
      if (!this.formState.phone) {
        message.warning('请先输入手机号!');
        return;
      }

      const res = await service.login();

      if (!res.code) {
        return;
      }

      if (this.countDownIndex) {
        clearInterval(this.countDownIndex);
      }
      this.countDown.idx = 60;
      this.countDownIndex = setInterval(() => {
        if (this.countDown.idx <= 0) {
          clearInterval(this.countDownIndex);
          return;
        }
        this.countDown.idx -= 1;
      }, 1000)
    },
    async onFinish(values) {
      if (values.repassword != values.password) {
        message.error('两次输入密码不一致, 请检查!')
        return;
      }
      if (!values.remember) {
        message.error('请先阅读协议');
        return;
      }
      delete values.remember;
      delete values.repassword;
      delete values.code;
      this.loading = true;
      const res = await service.register({ ...values });
      this.loading = false;
      // console.log(res);
      if (res.code) {
        message.success('注册成功!');
        window.sessionStorage.setItem('token', res.data);
        this.getUserinfo();
        setTimeout(() => {
          if (this.$route.query?.rewitchPath) {
            this.$router.replace(this.$route.query?.rewitchPath);
          }
          else {
            if (this.isAdmin) {
              this.$router.push('/platform/home');
            }
            else {
              this.$router.push('/work/home')
            }
          }
        }, 500)
      }
    },
    onFinishFailed(err) {
      console.log(err);
    }
  }
}
</script>
<style lang="less" scoped>
.text-area{white-space: pre-line;}
  .span{
    color: #3399FF;
    cursor: pointer;
  }
</style>