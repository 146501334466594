<template>
  <div class="page">
    <div class="main">
      <div class="title">
        <img src="@/assets/loginLogo.png" alt="" class="logo">
      </div>
      <div class="cont">
        <div class="qrcode">
          <div class="item" style="text-align:center">
            <div style="margin:20px auto; height:285px; width:400px; overflow:hidden; ">
                <iframe frameborder="0" :src="loginUrl" scrolling="no"  style="height:400px;width:400px; margin-top:-45px; over-flow:hidden"></iframe>
            </div>
            
            <div class="tip">微信扫码登录</div>
          </div>
        </div>
        <div class="login-box">
          <a-tabs v-model:activeKey="activeKey" centered>
            <a-tab-pane key="1" tab="账号密码登录" force-render>
              <Login />
            </a-tab-pane>
            <a-tab-pane key="2" tab="新用户注册" force-render>
              <Sign />
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import service from '@/service/service';
import { mapMutations } from 'vuex';
import Login from './components/login.vue';
import Sign from './components/sign.vue';

export default {
  components: { Login, Sign },
  data() {
    return {
      activeKey: "1",
      loginUrl:"",
    };
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapMutations(['setLogoUrl']),
    async getData() {
      const { data } = await service.get_setting_info({ key: 'system_logo' });
      // console.log(data);
      this.loginUrl = data.qr_code
      let files = data.icon ? JSON.parse(decodeURI(data.icon)) : [];
      this.logoUrl = files[0].url || null;
      if (this.logoUrl) {
        this.setLogoUrl(this.logoUrl);
      }
    }
  },
}
</script>
<style lang="less" scoped>
.page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  // background:#
  .main {
    background: #fff;
    width: 1200px;
    // margin: 24px;
    margin: auto;
    border-radius: 15px;
    padding-bottom: 200px;
  }

  .title {
    color: #000;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding: 24px 0;
    margin-bottom: 24px;
  }

  .cont {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    align-items: flex-start;
    // padding-top: 24px;
    padding: 0 110px;

    .qrcode {
      // width: 50%;
      flex: 1;
      border-radius: 10px;
      border: 1px solid #dedede;
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;

      .code {
        width: 208px;
        height: 208px;
        margin-bottom: 24px;
      }

      .tip {
        color: @text-color;
        text-align: center;
        font-size: 16px;
      }
    }

    .login-box {
      margin-left: 100px;
      // width: 50%;
      width: 340px;
      // padding: 0 100px;
    }
  }
}</style>